import { DeepPartial, UnpackNestedValue, useForm, UseFormReturn } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Schema } from 'joi';

export const useYelaForm = <T>(
  schema: Schema,
  defaultValues: UnpackNestedValue<DeepPartial<T>> | undefined = undefined,
): UseFormReturn<T> =>
  useForm<T>({
    mode: 'onBlur',
    defaultValues: defaultValues,
    resolver: joiResolver(schema),
  });
