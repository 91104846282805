interface AddTagEventDetails {
  conversionValue?: number;
  currency?: string;
  celebrity?: string;
  messageType?: string;
}

interface DataLayerInfo extends AddTagEventDetails {
  event: string;
}

export default {
  addTagEvent: (name: string, details?: AddTagEventDetails): void => {
    if (!process.env.NEXT_PUBLIC_G_ANALYTICS_KEY) return;
    const windowObj = window as any;
    let dataLayerInfo: DataLayerInfo = { event: name };
    if (details) {
      dataLayerInfo = { ...dataLayerInfo, ...details };
    }
    if (windowObj.dataLayer) windowObj.dataLayer.push(dataLayerInfo);
  },
};
