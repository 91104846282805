import EyeHide from '@assets/eye_hide';
import EyeShow from '@assets/eye_show';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import tw, { theme } from 'twin.macro';

export const bgStyle = css`
  ${tw`bg-transparent`}
  ${tw`focus:bg-white`}
`;

export const textStyle = css`
  ${tw`text-light-high`}
  ${tw`placeholder-light-low`}
  ${tw`focus:text-dark-high focus:placeholder-dark-low`}
`;

export const fontStyle = css`
  ${tw`text-subtitle-2 font-medium`}
`;

export const borderStyle = (hasError: boolean | undefined): SerializedStyles => css`
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  box-shadow: inset 0 0 0 1px ${theme`colors.light.medium`};

  &:focus {
    box-shadow: inset 0 0 0 2px ${theme`colors.brand.primary`};
  }

  ${hasError &&
  css`
    box-shadow: inset 0 0 0 1px ${theme`colors.brand.error`};

    &:focus {
      box-shadow: inset 0 0 0 2px ${theme`colors.brand.error`};
    }
  `}
`;

export const InputStyle = (hasError: boolean | undefined, isPasswordField: boolean): SerializedStyles => css`
  ${tw`transition-all`};
  ${tw`w-full h-8 px-2`};
  ${bgStyle}
  ${fontStyle}
  ${textStyle}
  ${borderStyle(hasError)}
  ${isPasswordField && tw`rounded-none`};
`;

export const EyeIconContainer = styled.div<{ hasError?: boolean }>`
  ${props =>
    !props.hasError &&
    css`
      border: 1px solid ${theme`colors.light.medium`};
    `}
  ${props =>
    props.hasError &&
    css`
      border: 1px solid ${theme`colors.brand.error`};
    `}
  ${tw`h-8 px-2 flex items-center border-s-0`};
`;

export const EyeStyle = css`
  ${tw`cursor-pointer`}
`;

export const EyeHideIcon = styled(EyeHide)`
  ${EyeStyle}
`;

export const EyeShowIcon = styled(EyeShow)`
  ${EyeStyle}
`;

export const FormInputWrapper = styled.div`
  ${tw`flex`}
`;
