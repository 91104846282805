import { TFormElementProps } from './FormElement.types';
import { InputError, InputHelper, InputLabel, InputSpacer, InputWrapper } from '@components/Form/Element/FormElement.styles';

const FormElement = ({ hasError, labelText, helperText, errorText, errorNoTruncate = false, children }: TFormElementProps): JSX.Element => {
  return (
    <InputWrapper>
      {labelText && <InputLabel title={labelText}>{labelText}</InputLabel>}
      {children}
      {!helperText && errorText && !hasError && <InputSpacer>&nbsp;</InputSpacer>}
      {helperText && !hasError && <InputHelper title={helperText}>{helperText}</InputHelper>}
      {errorText && hasError && (
        <InputError errorNoTruncate={errorNoTruncate} title={errorText}>
          {errorText}
        </InputError>
      )}
    </InputWrapper>
  );
};

export default FormElement;
