import { SVGProps } from 'react';

export default function EyeHide(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.3621 10.7855C20.3043 9.62598 19.0954 8.61387 17.7679 7.77641L21.1114 4.4371C21.1912 4.36 21.2549 4.26776 21.2987 4.16578C21.3425 4.0638 21.3656 3.95412 21.3665 3.84313C21.3675 3.73215 21.3464 3.62208 21.3043 3.51935C21.2623 3.41663 21.2002 3.3233 21.1217 3.24482C21.0433 3.16634 20.9499 3.10427 20.8472 3.06224C20.7445 3.02022 20.6344 2.99907 20.5234 3.00003C20.4124 3.001 20.3028 3.02405 20.2008 3.06786C20.0988 3.11167 20.0066 3.17535 19.9295 3.25518L19.9144 3.27023C19.8857 3.36143 19.8362 3.44471 19.7698 3.51347L3.47034 19.8129C3.46365 19.8188 3.45529 19.8205 3.4486 19.8272C3.37532 20.0057 3.3661 20.2042 3.4225 20.3888C3.4789 20.5734 3.59746 20.7327 3.75803 20.8399C3.91861 20.947 4.11131 20.9952 4.30341 20.9764C4.49551 20.9576 4.67518 20.8728 4.81191 20.7366L8.27743 17.2711C9.4148 17.7676 10.6392 18.0344 11.88 18.0559H12.1308C15.6883 18.0559 19.2265 15.5617 21.368 13.2079C21.6655 12.8743 21.8295 12.4426 21.8284 11.9956C21.8273 11.5486 21.6613 11.1177 21.3621 10.7855ZM15.6055 13.0599C15.424 13.6535 15.1008 14.194 14.664 14.6349C14.2272 15.0758 13.6897 15.4039 13.0979 15.591C12.2445 15.8627 11.3205 15.8061 10.5067 15.4321C10.4763 15.4174 10.4499 15.3956 10.4297 15.3686C10.4094 15.3415 10.396 15.31 10.3904 15.2767C10.3848 15.2434 10.3873 15.2093 10.3977 15.1771C10.408 15.145 10.4259 15.1158 10.4499 15.0919L15.0973 10.4453C15.121 10.4212 15.1501 10.4031 15.1822 10.3926C15.2144 10.3821 15.2486 10.3794 15.282 10.3849C15.3153 10.3904 15.3469 10.4039 15.3739 10.4241C15.401 10.4444 15.4228 10.4709 15.4375 10.5013C15.8062 11.3046 15.866 12.2154 15.6055 13.0599Z'
        fill='white'
        fillOpacity='0.43'
      />
      <path
        d='M8.3351 13.1686C8.36073 13.1428 8.37897 13.1105 8.38794 13.0753C8.3969 13.04 8.39627 13.0029 8.38609 12.968C8.29314 12.653 8.24364 12.3268 8.23898 11.9984C8.23898 11.0008 8.63527 10.0441 9.34067 9.33867C10.0461 8.63326 11.0028 8.23697 12.0004 8.23697C12.3287 8.24041 12.655 8.28907 12.97 8.38158C13.005 8.39165 13.0421 8.39215 13.0774 8.38304C13.1127 8.37392 13.1449 8.35552 13.1706 8.32975L14.793 6.70649C14.8187 6.68087 14.8374 6.64909 14.8473 6.6142C14.8572 6.57931 14.858 6.54247 14.8496 6.50718C14.8412 6.47189 14.8239 6.43934 14.7994 6.41263C14.7749 6.38592 14.7439 6.36594 14.7095 6.35459C13.8341 6.07364 12.9197 5.93314 12.0004 5.93833C8.40615 5.88985 4.81191 8.40415 2.64951 10.7855C2.34622 11.116 2.17745 11.5479 2.17637 11.9964C2.17528 12.4449 2.34196 12.8777 2.64366 13.2096C3.50728 14.1551 4.47081 15.0043 5.51738 15.7423C5.55849 15.7707 5.60824 15.7838 5.65801 15.7794C5.70778 15.7749 5.75443 15.7532 5.78988 15.718L8.3351 13.1686Z'
        fill='white'
        fillOpacity='0.43'
      />
    </svg>
  );
}
