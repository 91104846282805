import * as MixpanelAuthActions from '@analytics/mixpanel/actions/Auth';
import { EyeHideIcon, EyeIconContainer, EyeShowIcon, FormInputWrapper, InputStyle } from '@components/Form/Input/FormInput.styles';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { TFormInputProps } from './FormInput.types';

const FormInput = ({ name, type, hasError, placeholder, register, ...props }: TFormInputProps): JSX.Element => {
  const router = useRouter();
  const isPasswordField = type == 'password';
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    MixpanelAuthActions.onPasswordEyeIconClicked({ router, userAction: isPasswordShown ? 'Hide' : 'Show' });
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <FormInputWrapper>
      <input
        css={InputStyle(hasError, isPasswordField)}
        type={isPasswordShown && isPasswordField ? 'text' : type}
        placeholder={placeholder}
        autoComplete={type === 'email' ? 'on' : 'off'}
        {...props}
        {...register(name)}
      />
      {type == 'password' && (
        <EyeIconContainer hasError={hasError}>
          {isPasswordShown ? <EyeHideIcon onClick={togglePasswordVisibility} /> : <EyeShowIcon onClick={togglePasswordVisibility} />}
        </EyeIconContainer>
      )}
    </FormInputWrapper>
  );
};

export default FormInput;
