import { Label } from '@components/Texts/Texts';
import styled from '@emotion/styled';
import tw from 'twin.macro';

export const InputWrapper = styled.div`
  ${tw`w-full min-w-0 flex flex-col`}
`;

export const InputLabel = styled(Label)`
  ${tw`text-light-medium mb-1 truncate`};
`;

export const InputSpacer = styled(Label)`
  ${tw`mt-0.5`};
`;

export const InputHelper = styled(Label)`
  ${tw`text-light-low mt-0.5 px-2 truncate`};
`;

export const InputError = styled(Label)<{ errorNoTruncate: boolean }>`
  ${tw`text-brand-error mt-0.5 px-2`};
  ${props => !props.errorNoTruncate && tw`truncate`};
`;
