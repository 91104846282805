import GoogleAnalyticsFunctions from '@analytics/google/functions';
import HotjarFunctions from '@analytics/hotjar/functions';
import KlaviyoFunctions from '@analytics/klaviyo/functions';
import Mixpanel from '@analytics/mixpanel/Mixpanel';
import {
  OnFacebookAccessFailedInput,
  OnLoginButtonClickedInput,
  OnLoginFailedInput,
  OnLoginInput,
  OnLoginLinkClickedInput,
  OnPasswordEyeIconClickedInput,
  OnRegisterButtonClickedInput,
  OnRegisterFailedInput,
  OnRegisterInput,
  OnRegistrationTorPLinkClickedInput,
} from '@analytics/mixpanel/types/Auth.types';
import { BaseInput } from '@analytics/mixpanel/types/Mixpanel.types';

// Common
export const onPasswordEyeIconClicked = ({ router, userAction }: OnPasswordEyeIconClickedInput): void => {
  Mixpanel.track(
    `Password Eye Icon Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Page Name': router.asPath.startsWith('/register')
          ? 'Registration'
          : router.asPath.startsWith('/login')
          ? 'Login'
          : 'Forgot Password',
        'User Action': userAction,
      },
    }),
  );
};

export const onFacebookAccessButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(
    `Facebook Access Button Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onFacebookAccessFailed = ({ router, code }: OnFacebookAccessFailedInput): void => {
  HotjarFunctions.addTag('facebook_access_failed');
  Mixpanel.track(
    `Facebook Access Failed`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Error Code': code,
      },
    }),
  );
};

// Login Page
export const onLoginPageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.track(`Login Page Loaded`, Mixpanel.createEventProperties({ router }));
};

export const onRegistrationLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(
    `Login Registration Link Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onForgotPasswordLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(
    `Login Forgot Password Link Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onLoginButtonClicked = ({ router, email }: OnLoginButtonClickedInput): void => {
  Mixpanel.track(
    `Login Button Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Email: email,
      },
    }),
  );
};

export const onLogin = ({ router, data, authenticationMethod }: OnLoginInput): void => {
  HotjarFunctions.addTag('login_success');
  Mixpanel.identify(data?.id || '');
  KlaviyoFunctions.identify(data?.email);
  Mixpanel.people.set(`Last Visit Date`, new Date().toUTCString(), true);
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString(), true);
  Mixpanel.people.set(`Last Event`, `Login Success`, true);
  Mixpanel.track(
    `Login Success`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Authentication Method': authenticationMethod,
      },
    }),
  );
};

export const onLoginFailed = ({ router, code, email }: OnLoginFailedInput): void => {
  HotjarFunctions.addTag('login_failed');
  Mixpanel.track(
    `Login Failed`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Email: email,
        'Error Code': code,
      },
    }),
  );
};

// Registration Page
export const onRegistationPageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.track(`Registration Page Loaded`, Mixpanel.createEventProperties({ router }));
};

export const onLoginLinkClicked = ({ router, position }: OnLoginLinkClickedInput): void => {
  Mixpanel.track(
    `Registration Login Link Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Link Position': position,
      },
    }),
  );
};

export const onRegistrationTorPLinkClicked = ({ router, link }: OnRegistrationTorPLinkClickedInput): void => {
  Mixpanel.track(
    `Registration T or P Link Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Link: link,
      },
    }),
  );
};

export const onRegisterButtonClicked = ({ router, email }: OnRegisterButtonClickedInput): void => {
  Mixpanel.track(
    `Register Button Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Email: email,
      },
    }),
  );
};

export const onRegister = ({ router, data, email, authenticationMethod }: OnRegisterInput): void => {
  GoogleAnalyticsFunctions.addTagEvent('Registration_Success');
  HotjarFunctions.addTag('registration_success');
  Mixpanel.identify(data?.id || '');
  KlaviyoFunctions.identify(data?.email);
  Mixpanel.people.set(`$name`, data?.name || '', true);
  Mixpanel.people.set(`$email`, email, true);
  Mixpanel.people.set(`Last Visit Date`, new Date().toUTCString(), true);
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString(), true);
  Mixpanel.people.set(`Last Event`, `Registration Success`, true);
  Mixpanel.track(
    `Registration Success`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Authentication Method': authenticationMethod,
      },
    }),
  );
};

export const onRegisterFailed = ({ router, code, email, name }: OnRegisterFailedInput): void => {
  HotjarFunctions.addTag('registration_failed');
  Mixpanel.track(
    `Registration Failed`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Email: email,
        'User Name': name,
        'Error Code': code,
      },
    }),
  );
};

// Forgot Password Page
export const onPasswordRecoveryPageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.track(`Password Recovery Page Loaded`, Mixpanel.createEventProperties({ router }));
};

export const onSendRecoveryEmailButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(`Send Password Recovery Email Button Clicked`, Mixpanel.createEventProperties({ router }));
};

export const onChangePasswordPageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.track(`Change Password Page Loaded`, Mixpanel.createEventProperties({ router }));
};

export const onResendCodeLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(`Resend Code Link Clicked`, Mixpanel.createEventProperties({ router }));
};

export const onChangePasswordButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.track(`Change Password Button Clicked`, Mixpanel.createEventProperties({ router }));
};

export const onChangePasswordSuccess = ({ router }: BaseInput): void => {
  Mixpanel.track(`Change Password Success`, Mixpanel.createEventProperties({ router }));
};
